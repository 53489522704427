// @ts-ignore
const HCAPTCHA_SITE_KEY = ENV_HCAPTCHA_SITE_KEY as string;
const COMMENTS_PRIVACY_LINK = '';
const COMMENTS_TERMS_LINK = '';
const COOKIE_PREFIX = 'cn_t';
const BI_LINK_REGEX_FOR_TRACKING = '';
const COMMENTS_API_LINK = '';
const RANSOM_API = '';

export {
    HCAPTCHA_SITE_KEY,
    COOKIE_PREFIX,
    BI_LINK_REGEX_FOR_TRACKING,
    COMMENTS_PRIVACY_LINK,
    COMMENTS_TERMS_LINK,
    COMMENTS_API_LINK,
    RANSOM_API,
};
