import 'commonApp/js/parts/lazy-images';
import 'commonApp/js/window';
import 'commonApp/js/parts/lazy-js';
import 'commonApp/js/parts/active-on-scroll';
import './fast-track';
import './mobile-menu';
import 'commonApp/js/parts/cookie-policy';

document.addEventListener('DOMContentLoaded', () => {
    const toggle = document.querySelector('#toggle');
    const links = document.querySelectorAll('.link');

    for (let i = 0; i < links.length; i += 1) {
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        links[i].addEventListener('click', bindClick(i));
    }

    function bindClick(i) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        links[i].addEventListener('click', () => {
            // @ts-ignore
            toggle.checked = !toggle.checked;
            document.documentElement.style.overflow = 'initial';
        });
    }

    toggle?.addEventListener('change', () => {
        // @ts-ignore
        if (this.checked) {
            document.documentElement.style.overflow = 'hidden';
        } else {
            document.documentElement.style.overflow = 'initial';
        }
    });
});
