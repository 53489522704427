import { onDomReady, querySelectorAll } from '../utils/dom';
import { loadScriptFromElement } from './lazy-js';
// eslint-disable-next-line  @typescript-eslint/no-unused-vars
import appWindowObject from '../window';

const attributeName = 'data-js-active-on-scroll';
const lazyAttributeName = `${attributeName}-lazy`;
const onceEventAttributeName = `${attributeName}-once-event`;
const isVisibleClassName = 'active-on-scroll_visible';
const eventName = 'ui:on-scroll-visibility';

function getDocHeight(): number {
    return Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.offsetHeight,
        document.body.clientHeight,
        document.documentElement.clientHeight,
    );
}

enum UnitE {
    pixels = 'px',
    percentage = '%',
}

type ScrolledPositionT = {
    [UnitE.pixels]: number,
    [UnitE.percentage]: number
};

function getScrolledPosition(): ScrolledPositionT {
    const winHeight = window.innerHeight || (
        document.documentElement
        || document.body
    ).clientHeight;
    const docHeight = getDocHeight();
    const scrollTop = window.pageYOffset || (
        <HTMLElement>document.documentElement
        || <HTMLElement>document.body.parentNode
        || <HTMLElement>document.body
    ).scrollTop;
    const trackLength = docHeight - winHeight;

    return {
        [UnitE.pixels]: scrollTop,
        [UnitE.percentage]: Math.floor((scrollTop / trackLength) * 100),
    };
}

const scrollEventHandler = (
    element: HTMLElement,
    onVisibleHandler: () => void,
    onHideHandler: () => void,
): void => {
    const scrolledPosition = getScrolledPosition();
    const visibleOnScroll = element.getAttribute(attributeName) || '';
    const visibleScrollPosition = parseInt(visibleOnScroll, 10);
    const unit = (visibleOnScroll?.trim().endsWith('px')) ? UnitE.pixels : UnitE.percentage;

    if (scrolledPosition[unit] >= visibleScrollPosition) {
        loadScriptFromElement(element, lazyAttributeName);

        element.classList.add(isVisibleClassName);

        onVisibleHandler();
    } else {
        element.classList.remove(isVisibleClassName);

        onHideHandler();
    }
};

onDomReady(() => {
    const visibleOnScroll = querySelectorAll(`[${attributeName}]`);

    visibleOnScroll.forEach((domElement) => {
        const idAttribute = domElement.id;
        const eventHandler = () => {
            scrollEventHandler(domElement, () => {
                const isOnceEvent = domElement.hasAttribute(onceEventAttributeName);

                if (idAttribute && window.appWindowObject) {
                    window.appWindowObject.events.emit(eventName, idAttribute, true);
                }

                if (isOnceEvent) {
                    window.removeEventListener('scroll', eventHandler);
                }
            }, () => {
                if (idAttribute) {
                    window.appWindowObject.events.emit(eventName, idAttribute, false);
                }
            });
        };

        window.addEventListener('scroll', eventHandler);
    });
});

export {};
