const toggleCheckbox = document.getElementById('toggle') as HTMLInputElement;
const navbar = document.querySelector('.header__wrapper');
const body = document.querySelector('body');

const handleResize = () => {
    if (window.innerWidth > 991 && navbar.classList.contains('header__wrapper_open')) {
        body.classList.remove('overflow-hidden');
        navbar.classList.remove('header__wrapper_open');
        window.removeEventListener('resize', handleResize);
        toggleCheckbox.checked = false;
    }
};

toggleCheckbox.addEventListener('change', () => {
    if (navbar && toggleCheckbox.checked) {
        body.classList.add('overflow-hidden');
        navbar.classList.add('header__wrapper_open');
        window.addEventListener('resize', handleResize);
    } else if (navbar) {
        body.classList.remove('overflow-hidden');
        navbar.classList.remove('header__wrapper_open');
        window.removeEventListener('resize', handleResize);
    }
});

export {};
