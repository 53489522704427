import { on } from '../../utils/dom';
import { T_REFERRER_COOKIE, T_CLICKOUT_PLACE_COOKIE, setCookieValue } from '../../utils/cookie';
import { tableEventAction } from './tracking-table';
import { getLastArrayItem } from '../../utils/array';
import { BI_LINK_REGEX_FOR_TRACKING } from '../../constants';

export const clickOutElementSelector = '[data-js-clickout]';
const eventsSeparator = '|';

export type GTMDataLayerItem = {
    event: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string,
    eventValue?: string
};

export enum AffLinksEventActions {
    Button = 'clickedButton',
    MainButton = 'clicked|MainButton',
    InlineLink = 'clickedLink',
    CtaBadgeLink = 'clickedProTip',
}

export enum AffLinksEventCategories {
    MainButton = 'MainButton|affclickout',
}

export enum AffLinksEvent {
    MainButton = 'clickout|clickout',
}

export function pushToDl(GTMDataLayerItem: GTMDataLayerItem): void {
    window['dataLayer'] = window['dataLayer'] || [];

    if (typeof GTMDataLayerItem === 'object' && GTMDataLayerItem !== null) {
        window['dataLayer'].push(GTMDataLayerItem);
    }
}

export function getEventFromDatasetAtIndex(dataSet: DOMStringMap, name: string, index: number = 0) {
    return dataSet?.[name]?.split(eventsSeparator)?.[index]?.trim() ?? '';
}

export function processDomElementDataLayerEvents(domElement: HTMLElement): GTMDataLayerItem[] {
    const dataSet = domElement.dataset;
    const eventsCount = dataSet?.e?.split(eventsSeparator).length || 1;
    const isButton = domElement.classList.contains('button');
    const isLink = domElement.tagName === 'A';
    const labelDefault = domElement.getAttribute('title') || '';
    const isEventPrimaryAction = 'jsDlPrimaryAction' in dataSet;
    const result: GTMDataLayerItem[] = [];

    for (let index = 0; index < eventsCount; index += 1) {
        const getDataSetItem = (name: string) => getEventFromDatasetAtIndex(dataSet, name, index);
        const event = getDataSetItem('e') || 'clickout';
        const eventCategory = getDataSetItem('ec') || 'affclickout';
        const eventLabel = getDataSetItem('el') || labelDefault;
        let eventAction = getDataSetItem('ea');

        // data-js-dl-primary-action attribute help to skip adding suffix to event action
        if (!isEventPrimaryAction && (isButton || isLink)) {
            // eslint-disable-next-line max-len
            const action = eventAction || (isButton ? AffLinksEventActions.Button : AffLinksEventActions.InlineLink);

            eventAction = `${action} > ${domElement.innerText.trim()}`;
        }

        const item: GTMDataLayerItem = {
            event,
            eventCategory,
            eventAction,
            eventLabel,
        };

        result.push(item);
        pushToDl(item);
    }

    return result;
}

export function handleClickoutEvent(eventTarget: HTMLElement) {
    const domLink = eventTarget.closest(clickOutElementSelector) as HTMLLinkElement;

    if (domLink) {
        if ((BI_LINK_REGEX_FOR_TRACKING).test(domLink.href)) {
            setCookieValue(T_REFERRER_COOKIE, document.location.href, true);
        }

        const gtmDataLayerItems = processDomElementDataLayerEvents(domLink);

        if (gtmDataLayerItems) {
            // eslint-disable-next-line max-len
            const affItemsFiltered = gtmDataLayerItems.filter((item) => item.eventCategory === 'affclickout');

            const mainAffLink = getLastArrayItem(affItemsFiltered);

            if (mainAffLink) {
                setCookieValue(T_CLICKOUT_PLACE_COOKIE, `${mainAffLink.eventAction}`, true);
            }
        }
    }
}

export function onAllClickEvents(selector: string, handler: (eventTarget: HTMLElement) => void) {
    on('click', selector, (e, eventTarget) => {
        handler(eventTarget);
    });

    on('auxclick', selector, (e: MouseEvent, eventTarget) => {
        if (e.button === 1) {
            handler(eventTarget);
        }
    });
}

export function forMainButton(mainButton: HTMLButtonElement) {
    const alias = mainButton.getAttribute('data-el');

    mainButton.setAttribute('data-ea', AffLinksEventActions.MainButton);
    mainButton.setAttribute('data-ec', AffLinksEventCategories.MainButton);
    mainButton.setAttribute('data-el', `${alias}|${alias}`);
    mainButton.setAttribute('data-e', AffLinksEvent.MainButton);
}

export function init(changeTableEventAction = true) {
    const dlElementSelector = '[data-js-dl]';
    const mainButton = document.querySelector(`[data-ea="${AffLinksEventActions.Button}"]`) as HTMLButtonElement;

    onAllClickEvents(clickOutElementSelector, handleClickoutEvent);

    if (changeTableEventAction) {
        tableEventAction(clickOutElementSelector);
    }

    if (mainButton) {
        forMainButton(mainButton);
    }

    onAllClickEvents(dlElementSelector, (eventTarget) => {
        const domLink = eventTarget.closest(dlElementSelector) as HTMLLinkElement;

        if (domLink) {
            processDomElementDataLayerEvents(domLink);
        }
    });

    window.appWindowObject.events.on('tracking:push', pushToDl);
}

export default {
    init,
};
